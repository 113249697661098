.food_container {
  font-family: var(--secondaryFont);
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.food_hero_image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}
.food_paragraph {
  justify-content: center;
  margin: 2rem 0;
  width: 45vw;
}
.food_title_paragraph {
  font-size: var(--fontSizeLarge);
  margin: 0;
}
.food_paragraph p {
  font-size: var(--fontSizeMedium);
}
.food_meal_details {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 0;
}
.food_meal_label {
  font-weight: var(--fontWeightBold);
}
.food_image_carousel {
  width: inherit;
  max-height: 70vh;
  object-fit: contain;
}
.food_logo_image {
  max-height: 18vh;
  margin: 2rem 0 0 0;
}

@media only screen and (max-width: 750px) {
  .food_paragraph {
    margin: 1rem 0;
    width: 80vw;
  }
  .food_hero_image {
    margin-bottom: 1rem;
  }
  .food_meal_details {
    grid-template-columns: 1fr;
    margin: 0 0 2rem 0;
    padding: 0 1rem;
    border-radius: 5px;
    background-color: var(--tertiaryColor);
  }
  .food_meal_label {
    margin: 1rem 0 0 0;
  }
  .food_image_carousel {
    max-height: 40vh;
    object-fit: cover;
  }
  .food_logo_image {
    display: none;
  }
}
