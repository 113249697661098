.to_top_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  height: 90px;
  padding: 0;
  color: var(--primaryColor);
  border: 2px solid var(--primaryColor);
  background-color: var(--tertiaryColor);
  position: fixed;
  right: 6%;
  bottom: 10%;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.to_top_button:hover {
  background-color: var(--backgroundColor);
}

.to_top_text {
  font-family: var(--primaryFont);
  font-size: var(--fontSizeSmall);
  color: var(--primaryColor);
  font-weight: var(--fontWeightBold);
  white-space: nowrap;
}

@media only screen and (max-width: 500px) {
  .to_top_button {
    position: static;
    width: 60px;
    height: 60px;
    padding: 5px;
  }
  .to_top_text {
    font-size: var(--fontSizeExtraSmall);
    margin: 0.25rem 0 0 0;
  }
}
