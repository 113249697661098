.sleep_container {
  font-family: var(--secondaryFont);
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sleep_hero_image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}
.sleep_paragraph {
  justify-content: center;
  margin: 2rem 0;
  width: 45vw;
}
.sleep_title_paragraph {
  font-size: var(--fontSizeLarge);
  margin: 0;
}
.sleep_paragraph p {
  font-size: var(--fontSizeMedium);
  margin-bottom: 2rem;
}
.room_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  background-color: var(--tertiaryColor);
  width: 45vw;
  border-radius: 5px;
  font-size: var(--fontSizeMedium);
}
.room_paragraph {
  padding: 1rem;
}
.room_title {
  margin: 0;
}
.room_picture {
  width: 60%;
  object-fit: cover;
  border-radius: 0 5px 5px 0;
}

@media only screen and (max-width: 750px) {
  .room_container {
    flex-direction: column-reverse;
  }
  .room_picture {
    width: 100%;
    margin: auto;
    border-radius: 5px 5px 0 0;
  }
}
@media only screen and (max-width: 500px) {
  .sleep_paragraph {
    margin: 1rem 0;
    width: 80vw;
  }
  .sleep_hero_image {
    margin-bottom: 1rem;
  }
  .room_container {
    width: 80vw;
  }
}
