.host_container {
  font-family: var(--secondaryFont);
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.host_hero_image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}
.host_paragraph {
  justify-content: center;
  margin: 2rem 0;
  width: 45vw;
  line-height: 1.5;
}
.host_ingress {
  font-size: var(--fontSizeExtraLarge);
  font-family: var(--secondaryFont);
  font-style: italic;
  display: block;
  margin-bottom: 1.5rem;
}
.host_details {
  display: block;
  font-size: var(--fontSizeMedium);
  font-family: var(--primaryFont);
}

@media only screen and (max-width: 500px) {
  .host_hero_image {
    margin-bottom: 1rem;
  }
  .host_paragraph {
    margin: 1rem 0;
    width: 80vw;
    line-height: 1.2;
  }
  .host_ingress {
    font-size: var(--fontSizeLarge);
  }
}
