.contact_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact_content {
  font-family: var(--secondaryFont);
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--fontSizeMedium);
}
.contact_title {
  font-size: var(--fontSizeLarge);
  margin: 3rem 0;
}
.contact_info {
  margin-bottom: 2rem;
}
.contact_person_picture {
  margin: auto;
  padding: 1rem 0;
  width: 25vh;
  object-fit: cover;
  border-radius: 50%;
}
.contact_person {
  span {
    display: block;
    line-height: 1.7rem;
  }
}
.contact_name {
  font-weight: var(--fontWeightBold);
  text-align: center;
  margin-bottom: 1rem;
}
.contact_mail_link {
  color: black;
  font-weight: var(--fontWeightBold);
  cursor: pointer;
}
.contact_mobile_number {
  text-decoration: underline;
  font-weight: var(--fontWeightBold);
  color: black;
}
.contact_address {
  line-height: 1.7rem;
  margin-top: 1rem;
  font-style: normal;
  span {
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  .contact_content {
    width: 80vw;
  }
}
