.header_container {
  background-color: var(--primaryColor);
  font-family: var(--primaryFont);
  height: 10vh;
  display: flex;
  width: 100%;
  position: sticky;
}
.header_innerContainer {
  width: 65vw;
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: space-between;
  align-items: stretch;
}
.header_content {
  display: flex;
  flex-direction: column;
}
.header_logo {
  max-height: 8.5vh;
}
.header_dt_navbar {
  display: none;
  flex-direction: row;
  align-items: end;
  height: 100%;
  justify-content: space-evenly;
}
.header_mb_navbar {
  display: block;
}
.item_selected {
  color: var(--secondaryColor);
  padding: 10px;
  font-weight: var(--fontWeightBold);
  white-space: nowrap;
}
.item {
  color: var(--tertiaryColor);
  font-size: var(--fontSizeSmall);
  cursor: pointer;
  padding: 10px;
  white-space: nowrap;
}
.item:hover {
  background-color: rgb(103, 122, 114);
}

@media only screen and (max-width: 750px) {
  .header_innerContainer {
    width: 95vw;
  }
  .header_content {
    justify-content: space-between;
  }
  .header_logo {
    margin-top: 0.5rem;
    max-height: 7vh;
  }
}

@media only screen and (min-width: 1150px) {
  .header_dt_navbar {
    display: flex;
  }
  .header_mb_navbar {
    display: none;
  }
}
