.activities_container {
  font-family: var(--secondaryFont);
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activities_hero_image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}
.activities_paragraph {
  justify-content: center;
  margin: 2rem 0;
  width: 45vw;
}
.activities_title_paragraph {
  font-size: var(--fontSizeLarge);
  font-weight: var(--fontWeightBold);
  margin: 0;
}
.activities_paragraph p {
  font-size: var(--fontSizeMedium);
}
.activities_details {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.activities_paragraph_label {
  font-weight: var(--fontWeightBold);
}

@media only screen and (max-width: 750px) {
  .activities_paragraph {
    margin: 1rem 0;
    width: 80vw;
  }
  .activities_hero_image {
    margin-bottom: 1rem;
  }
  .activities_details {
    grid-template-columns: 1fr;
    margin: 0 0 2rem 0;
    padding: 0 1rem;
    border-radius: 5px;
    background-color: var(--tertiaryColor);
  }
  .activities_paragraph_label {
    margin: 1rem 0 0 0;
  }
}
