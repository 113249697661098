.carousel {
  overflow: hidden;
  position: relative;
  max-height: 70vh;
}
.inner {
  white-space: nowrap;
  transition: transform 0.5s;
}
.carousel_item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-height: 60vh;
  width: 100%;
  border-radius: 5%;
}
.carousel_buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 10px;
  color: var(--primaryColor);
  border: 2px solid var(--primaryColor);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 2.4px 2.4px 3.2px;
  position: absolute;
  top: 50%;
  z-index: 1;
}
.carousel_buttons:hover {
  background-color: var(--backgroundColor);
}
.carousel_button_previous {
  left: 5px;
}
.carousel_button_next {
  right: 5px;
}
.indicators {
  display: flex;
  justify-content: center;
  background: transparent;
}
.indicators > button {
  margin: 0.7rem;
  padding: 0.5rem;
  height: 0.3rem;
  width: 0.3rem;
  border-radius: 50%;
  background-color: var(--primaryColor);
  border: none;
  cursor: pointer;
}
.indicators > button.active {
  background-color: var(--secondaryColor);
}

@media only screen and (max-width: 500px) {
  .carousel_item {
    max-height: 70vh;
  }
  .carousel_buttons {
    width: 2.4rem;
    height: 2.4rem;
    padding: 0;
    top: 42%;
  }
  .carousel_button_previous {
    left: 10px;
  }
  .carousel_button_next {
    right: 10px;
  }
  .indicators > button {
    margin: 0.5rem;
    padding: 0.35rem;
  }
}
