.garden_container {
  font-family: var(--secondaryFont);
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.garden_hero_image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}
.garden_paragraph {
  justify-content: center;
  margin: 2rem 0;
  width: 45vw;
}
.garden_title_paragraph {
  font-size: var(--fontSizeLarge);
  margin: 0;
}
.garden_paragraph p {
  font-size: var(--fontSizeMedium);
  margin-bottom: 2rem;
}
.garden_image_carousel {
  width: inherit;
  max-height: 70vh;
  object-fit: contain;
}
.garden_logo_image {
  max-height: 18vh;
  margin: 2rem 0 0 0;
}

@media only screen and (max-width: 500px) {
  .garden_paragraph {
    margin: 1rem 0;
    width: 80vw;
  }
  .garden_hero_image {
    margin-bottom: 1rem;
  }
  .garden_image_carousel {
    max-height: 40vh;
    object-fit: cover;
  }
  .garden_logo_image {
    display: none;
  }
}
