body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 60%;
  margin: 0 auto 5% auto;
}

h1 {
  font-size: var(--fontSizeLarge);
  font-weight: var(--fontWeightBold);
}

h2 {
  font-size: var(--fontSizeLarge);
  font-weight: var(--fontWeightBold);
}

h3 {
  font-size: var(--fontSizeMedium);
  font-weight: var(--fontWeightBold);
}

p {
  font-size: var(--fontSizeSmall);
}

@media only screen and (max-width: 500px) {
  .container {
    margin: 0px;
    width: 100%;
  }

  .to_top_container {
    display: flex;
    justify-content: center;
    padding: 5% 0;
  }
}
