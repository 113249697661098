.burger_menu_button {
  display: flex;
  color: var(--tertiaryColor);
  font-size: var(--fontSizeExtraLarge);
  font-family: var(--primaryFont);
  text-align: center;
  justify-content: flex-end;
  margin-left: auto;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.burger_menu_container {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(--primaryColor);
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: -10px 0px 13px -7px #000000;
  font-family: var(--primaryFont);
}

.close_menu_button {
  margin-left: auto;
  padding: 1px 8px 0px 8px;
  border: none;
  background: transparent;
  text-decoration: none;
  color: var(--tertiaryColor);
  cursor: pointer;
  font-size: var(--fontSizeMedium);
}

.burger_menu_item {
  display: flex;
  min-height: 50px;
  align-items: center;
  color: var(--tertiaryColor);
  font-size: var(--fontSizeSmall);
  padding: 0px 15px;
  letter-spacing: 1px;
  text-decoration: none;
}

.burger_menu_item_selected {
  background-color: rgb(58, 70, 64);
  color: #c8ba42;
}
.burger_menu_item:hover {
  background-color: var(--hoverColor);
}
