.footer_container {
  background-color: var(--primaryColor);
  flex-direction: column;
  bottom: 0;
  color: var(--tertiaryColor);
  font-family: var(--primaryFont);
  display: flex;
  font-weight: var(--fontWeightRegular);
  line-height: normal;
  padding: 0.75rem 0 1rem 0;
}
.footer_title {
  text-align: center;
  font-size: var(--fontSizeMedium);
  font-family: var(--secondaryFont);
  padding: 0 0 0.4rem 0;
  letter-spacing: 0.15rem;
  color: var(--tertiaryColor);
}
.footer_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65vw;
  margin: auto;
}
.left_container,
.right_container {
  flex: 1;
}
.left_container p,
.right_container p {
  font-size: var(--fontSizeSmall);
  color: var(--tertiaryColor);
}
.contact {
  line-height: 0.5;
  margin: 2rem 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.footer_address {
  font-size: var(--fontSizeSmall);
}
.right_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.copyright {
  margin-left: auto;
}
.social_medias_text {
  margin-left: auto;
  line-height: 0.5;
  margin-bottom: 2rem;
}
.links {
  color: var(--secondaryColor);
  text-decoration: none;
  cursor: pointer;
}
.links:hover {
  color: var(--tertiaryColor);
}
.social_medias_icons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 0 2.4rem;
}
.social {
  height: 2.3rem;
  width: 2.3rem;
}
.social:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .footer_content {
    flex-direction: column;
    width: 90vw;
  }
  .left_container p,
  .right_container p,
  .contact {
    margin: 0.4rem 0;
    line-height: normal;
  }
  .footer_address,
  .social_medias_text {
    display: none;
  }
  .social_medias_icons {
    margin: 0;
    justify-content: space-between;
    gap: 0;
    padding-top: 1rem;
  }
  .social {
    height: 1.3rem;
    width: 1.3rem;
  }
}

@media only screen and (max-width: 500px) {
  .footer_container {
    padding: 0rem 0 1.6rem 0;
  }
  .footer_title {
    padding: 0;
  }
}
