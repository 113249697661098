:root {
  /* Colors */
  --primaryColor: #44514b;
  --secondaryColor: #c8ba42;
  --tertiaryColor: #ffffff;
  --backgroundColor: #e5e5e5;
  --hoverColor: rgb(103, 122, 114);

  /* Font Families */
  --primaryFont: 'Maven Pro', sans-serif;
  --secondaryFont: 'EB Garamond', sans-serif;

  /* Font Sizes */
  --fontSizeExtraSmall: 0.55rem;
  --fontSizeSmall: 1rem;
  --fontSizeMedium: 1.2rem;
  --fontSizeLarge: 1.4rem;
  --fontSizeExtraLarge: 2rem;

  /* Font Weights */
  --fontWeightRegular: 400;
  --fontWeightBold: 700;
  --fontWeightExtraBold: 1000;
}
