.home_container {
  font-family: var(--primaryFont);
  color: var(--primaryColor);
}
.home_hero_image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}
.home_paragraph {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
}
.home_first_paragraph_text,
.home_second_paragraph_text {
  font-style: italic;
  font-family: var(--secondaryFont);
  font-size: var(--fontSizeExtraLarge);
  width: 80%;
  text-align: center;
}
.home_third_section {
  width: 45vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home_full_logo_image {
  max-height: 9vh;
}
.home_third_paragraph_text {
  font-size: var(--fontSizeLarge);
  max-width: 30vw;
}

@media only screen and (max-width: 500px) {
  .home_container {
    font-size: var(--fontSizeSmall);
  }
  .home_paragraph {
    margin: 1rem 0;
  }
  .home_first_paragraph_text,
  .home_second_paragraph_text {
    font-size: var(--fontSizeMedium);
  }
  .home_third_paragraph_text {
    font-size: var(--fontSizeSmall);
    max-width: 80vw;
  }
  .home_third_section {
    flex-direction: column;
    width: 80vw;
  }
}
