.stone_container {
  font-family: var(--secondaryFont);
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stone_hero_image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}
.stone_paragraph {
  justify-content: center;
  margin: 2rem 0;
  width: 45vw;
}
.stone_title {
  font-size: var(--fontSizeLarge);
  margin: 0;
}
.stone_paragraph {
  p {
    font-size: var(--fontSizeMedium);
    margin-bottom: 2rem;
  }
  span {
    margin: 1.75rem 0;
    display: block;
  }
}
.stone_images_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stone_image {
  object-fit: cover;
  width: 15rem;
  height: 15rem;
}

@media only screen and (max-width: 1600px) {
  .stone_images_container {
    flex-direction: column;
    align-items: center;
    justify-content: normal;
    gap: 2rem 0;
  }
  .stone_image {
    width: 35rem;
    height: 35rem;
  }
}

@media only screen and (max-width: 750px) {
  .stone_images_container {
    gap: 1rem 0;
  }
  .stone_image {
    width: 20rem;
    height: 20rem;
  }
}

@media only screen and (max-width: 500px) {
  .stone_hero_image {
    margin-bottom: 1rem;
  }
  .stone_paragraph {
    margin: 1rem 0;
    width: 80vw;
  }
  .stone_images_container {
    gap: 1.5rem 0;
  }
  .stone_image {
    width: 100%;
    max-height: 18rem;
  }
}
