.header_language_settings {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}

.header_language_button {
  font-family: var(--primaryFont);
  color: var(--tertiaryColor);
  background: transparent;
  border: none;
  font-size: var(--fontSizeSmall);
  padding: 10px 0;
  min-width: 80px;
  text-align: center;
}

.header_language_button:hover {
  background-color: var(--hoverColor);
  cursor: pointer;
}

.activeLang {
  color: var(--secondaryColor);
  font-weight: var(--fontWeightExtraBold);
}

@media only screen and (max-width: 500px) {
  .header_language_button {
    padding: 0;
    margin: 0 2rem;
    min-width: 45px;
    margin-right: auto;
  }
  .header_language_button:hover {
    background: transparent;
  }
  .activeLang {
    background: transparent;
  }
}
